import React from 'react';
import { useEffect, useState } from 'react';

export default function QuickSight() {
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('token');
  console.log('Token')

  const getUrl = async () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/quicksight/', {
      method: "GET",
      headers: {
        "Authorization": "bearer " + token
      }
    })
      .then((response) => {
        if (response.status >= 400) {
          console.log(response)
          throw new Error("There was an error fetching your dashboard");
        }
        return response.json()
      })
      .then(json => {
        setUrl(json.url)
      },
        err =>
          setError(err.message || "Error Occurred"))
  }

  useEffect(() => {
    if (token) getUrl();
  }, [token])

  return (
    <>
      {error !== null && error}
      {url &&
        <iframe
          src={url}
          title="quicksight"
          height="100%"
          width="100%"
        />
      }
    </>
  );
}
