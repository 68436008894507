import "./App.css";
import QuickSight from "./QuickSight";

export default function App() {


  return (
    <div className="App">
      <QuickSight />
    </div>
  );
}
